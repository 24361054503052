<template>
    <div id="date-picker" @click="chevronRotate">
        <date-picker
            range
            v-model="period"
            :type="type"
            :format="format"
            :clearable="true"
            :lang="langDatePicker"
            v-on:blur="resetChevronState"
            prefix-class="dx"
            class="dx-datepicker"
            placeholder="Selecione um intervalo"
        >
            <template slot="icon-calendar">
                <div id="chevron">
                    <v-chevron-down class="datepicker-chevrondown-icon" />
                </div>
            </template>
        </date-picker>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: 'date',
        },
        format: {
            type: String,
            default: 'DD [de] MMMM',
        },
    },
    components: {
        'v-chevron-down': () => import('@/assets/icons/chevron-down.svg'),
    },
    data() {
        return {
            chevronState: false,
            period: [null, null],
            langDatePicker: {
                formatLocale: {
                    weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
                },
            },
        }
    },
    methods: {
        chevronRotate() {
            if(this.chevronState === false) {
                const chevron = document.getElementById('chevron')
                chevron.classList.toggle('chevron-active')
                this.chevronState = true
            }
        },
        resetChevronState() {
            if(this.chevronState === true) {
                const chevron = document.getElementById('chevron')
                chevron.classList.toggle('chevron-active')
                this.chevronState = false
            }
        },
    },
    watch: {
        period(value) {
            this.$emit('input', value)
            this.$emit('select', value)
        },
    },
}
</script>
<style lang="scss" scoped>
#date-picker {
    .dx-datepicker {
        caret-color: transparent;
        #chevron {
            cursor: pointer;
            transition: all 500ms;
        }
        .datepicker-chevrondown-icon {
            fill: transparent;
            stroke: var(--blue-500);
            height: 24px;
            width: 24px;
        }
        .chevron-active {
            transform: rotate(-180deg);
        }
    }
}
</style>